'use client';
import escapeChar from '@/utils/escapeChar';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ChevronDown from '../svgs/ChevronDown';
import './carousel.css';
const ProductSlider = ({ className, children }) => {
    const sliderRef = useRef(null);
    const [totalSlides, setTotalSlide] = useState();
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(true);
    const slidesPerSlide = 3;

    const prevSlide = () => {
        const slider = sliderRef.current;
        if (!slider) return;
        const detectCardWidth = slider.querySelector('*').offsetWidth;
        const sliderGap = window.getComputedStyle(slider).gap;
        const scrollAmount = detectCardWidth + escapeChar(sliderGap) * 2;
        const previousScroll = slider.scrollLeft || 0;
        const newScroll = previousScroll - scrollAmount * slidesPerSlide;
        slider.scroll({
            left: newScroll,
            behaviour: 'smooth',
        });
    };
    const nextSlide = () => {
        const slider = sliderRef.current;
        if (!slider) return;
        const detectCardWidth = slider.querySelector('*').offsetWidth;
        const sliderGap = window.getComputedStyle(slider).gap;
        const scrollAmount = detectCardWidth + escapeChar(sliderGap) * 2;
        const previousScroll = slider.scrollLeft;
        const newScroll = previousScroll + scrollAmount * slidesPerSlide;
        slider.scroll({
            left: newScroll,
            behaviour: 'smooth',
        });
    };

    const handleHorizScroll = useCallback((scrollEvent) => {
        const sliderElement = scrollEvent.currentTarget;
        const scrollLeft = sliderElement.scrollLeft;
        const sliderScrollWidth = sliderElement.scrollWidth;

        const totalSlides = Array.from(sliderElement.children);
        // console.log(totalSlides);
        const sliderGap = window.getComputedStyle(sliderElement).gap;

        const totalScrollLeftWithGap = scrollLeft;
        // console.log(totalScrollLeftWithGap, sliderScrollWidth, totalGap);

        const sliderWidth = sliderElement.offsetWidth;
        // console.log(sliderWidth);
        const allSlidesWidth = totalSlides.reduce(
            (total, accum) => {
                const slideWidth = accum.offsetWidth + escapeChar(sliderGap);
                const totalWidth = slideWidth + +total;

                return totalWidth;
            },
            [0],
        );

        // console.log(reachLastCardsWidthToSliderWidth);
        // console.log(allSlidesWidth - escapeChar(sliderGap), sliderScrollWidth);

        const restCardsWidthAfterCompleteScrolledLeft =
            allSlidesWidth - escapeChar(sliderGap) - scrollLeft;
        // console.log(
        //   isPrev,
        //   isNext,
        //   Math.floor(restCardsWidthAfterCompleteScrolledLeft)
        // );
        console.log(totalScrollLeftWithGap);
        const isPrev = totalScrollLeftWithGap !== 0;
        const isNext =
            Math.floor(restCardsWidthAfterCompleteScrolledLeft) !== sliderWidth;
        console.log(
            Math.floor(restCardsWidthAfterCompleteScrolledLeft),
            sliderWidth,
        );
        console.log(isPrev, isNext);
        // if () {
        //   setPrev(false);
        // }else{
        //   setPrev(true);
        // }
        setPrev(isPrev);
        setNext(isNext);
    }, []);

    useEffect(() => {
        const slider = sliderRef.current;
        if (!slider) return;
        slider.addEventListener('scroll', handleHorizScroll);

        return () => {
            slider.removeEventListener('scroll', handleHorizScroll);
        };
    }, [handleHorizScroll]);

    // settign total slide
    useEffect(() => {
        // console.log(children);
        const totalSlides = children && children[1]?.length;
        // console.log(totalSlides);
        setTotalSlide(totalSlides);
    }, [children]);

    return (
        <div className="product-slider-container ">
            <CSSTransition
                in={prev}
                timeout={300}
                unmountOnExit
                classNames={'prev-btn'}
            >
                <div
                    className="product-slider-btn slider-prev-btn"
                    onClick={prevSlide}
                >
                    <ChevronDown className="w-[16px] md:w-[20px] rotate-90" />
                </div>
            </CSSTransition>

            <div className={clsx('product-slider ', className)} ref={sliderRef}>
                {children}
            </div>
            <CSSTransition
                in={next}
                timeout={300}
                unmountOnExit
                classNames={'next-btn'}
            >
                <div
                    className="product-slider-btn slider-next-btn"
                    onClick={nextSlide}
                >
                    <ChevronDown className="w-[16px] md:w-[20px] -rotate-90" />
                </div>
            </CSSTransition>
        </div>
    );
};

export default ProductSlider;
