import React from "react";

const ChevronDown = (props) => {
  return (
    <svg
      stroke="currentColor"
      fill="#333"
      strokeWidth={0}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      {...props}
    >
      <path d="M5.22 8.22a.749.749 0 0 0 0 1.06l6.25 6.25a.749.749 0 0 0 1.06 0l6.25-6.25a.749.749 0 1 0-1.06-1.06L12 13.939 6.28 8.22a.749.749 0 0 0-1.06 0Z" />
    </svg>
  );
};

export default ChevronDown;
