const escapeChar = (string) => {
    const numberString = string
        .split("")
        .map((digLet) => {
            const isNumber = +digLet;
            console.log(isNumber);
            if (isNumber >= 0) {
                console.log(digLet);
                return digLet;
            }
        })
        .join("");
    console.log(Number(numberString));
    return Number(numberString);
};

export default escapeChar;