"use client"
import React from "react"
import {
  BottomSheet,
  CSSTransition,
  TransitionGroup,
  useEffect,
} from "@/index";
import { clsx } from "clsx";
import Image from "next/image";
import "./bottomsheetchild.css";
import "./loader.css";

const HomePageBottomSheet = ({
  isSheetOpen,
  setIsSheetOpen,
  children,
  isLoader,
  setIsContentLoaded,
}) => {
  useEffect(() => {
    setTimeout(() => setIsContentLoaded(true), 800);
  }, []);

  return (
    <BottomSheet
      isOpen={isSheetOpen}
      setIsOpen={setIsSheetOpen}
      height={"90dvh"}
    >
      {isSheetOpen && (
        <TransitionGroup>
          {isLoader && (
            <CSSTransition
              timeout={300}
              classNames="loader"
              key="loader-screen"
              unmountOnExit
            >
              <div className="loader-screen">
                <Image
                  src="/svgs/loader-ring.svg"
                  alt="loader"
                  width={30}
                  height={30}
                ></Image>
              </div>
            </CSSTransition>
          )}
          {!isLoader && (
            <CSSTransition
              timeout={300}
              classNames="sheet-children"
              key="amf-modal-sheet"
              unmountOnExit
            >
              <div
                className={clsx(`
                  h-[calc(90dvh_-_env(safe-area-inset-top))] 
                  relative
                  overflow-auto
                `)}
              >
                {children}
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      )}
    </BottomSheet>
  );
};
export default React.memo(HomePageBottomSheet);
