'use client';
import ProductSlider from '@/components/Carousel/ProductSlider';
import { clsx } from 'clsx';
import { useRef } from 'react';
import './slider.css';

const SliderWrapper = ({ settings, className, children }) => {
  let sliderRef = useRef(null);
  //   const [currentSlide, setCurrentSlide] = useState(0);
  //   const [totalSlides, setTotalSlides] = useState(0);

  const slickPrev = () => {
    sliderRef.current.slickPrev();
  };
  const slickNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <>
      {/* <Slider {...settings} ref={sliderRef} className={clsx("px-4", className)}>
        {children}
      </Slider> */}
      {/* <div {...settings} ref={sliderRef} className={clsx("px-4 flex overflow-auto", className)}>
        {children}
      </div> */}
      <ProductSlider
        {...settings}
        ref={sliderRef}
        className={clsx('flex overflow-auto', className)}
      >
        {children}
      </ProductSlider>
      {/* <div className="navigation-btns-container">
        <div className={`navigation-btn prev-btn `} onClick={slickPrev}>
          <ChevronDown className="w-[16px] md:w-[20px] rotate-90" />
        </div>
        <div className={`navigation-btn next-btn`} onClick={slickNext}>
          <ChevronDown className="w-[16px] md:w-[20px] -rotate-90" />
        </div>
      </div> */}
    </>
  );
};

export default SliderWrapper;
