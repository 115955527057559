import { useEffect } from "react";

const useFullScreen = (state) => {
    useEffect(() => {
        const body = document.body;
        if (state) {
            // window.scrollY(0;
            const scrollY =
                document.documentElement.style.getPropertyValue("--scroll-y");
            body.style.position = "fixed";
            body.style.top = `-${scrollY}`;
            body.style.width = "100%";
        } else {
            const scrollY = body.style.top;
            body.style.position = "";
            body.style.top = "";
            // body.style.height = "auto";
            // body.style.overflow = "auto";
            window.scrollTo(0, parseInt(scrollY || "0") * -1);
        }

        const handleScroll = () => {
            document.documentElement.style.setProperty(
                "--scroll-y",
                `${window.scrollY}px`
            );
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [state]);
}

export default useFullScreen;