"use client"

// React important imports
import { default as React , useState,useEffect,useMemo,useCallback,useContext,memo,lazy,useRef,Suspense } from "react";
export { useSearchParams, usePathname } from "next/navigation";
import dynamic from "next/dynamic";

export {default as clsx} from "clsx";
// React-transition-group
export { TransitionGroup,CSSTransition } from 'react-transition-group';

// components imports
export {default as BottomSheet} from "@/components22/common/BottomSheet"
const ContinentsMap = dynamic(() => import("./components22/pages/home/WhereTo/WhereToBottomSheetSteps/MapSelectionPhase/WorldContinentsMap/ContinentsMap") , {ssr:false});
export const MemoContinentsMap = memo(ContinentsMap);
// export { dynamic, memo };

// export { default as BottomSheetComponent } from "@/components22/common/BottomSheetComponent"

// react exports
export {React , useState,useEffect,useMemo,useCallback,useContext,memo,lazy,useRef,Suspense}