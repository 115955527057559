'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

const Tab = ({ _id, name, image, firstDestination }) => {
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const currentParams = new URLSearchParams(searchParams);
    const countryName = currentParams.get('country');
    const isActive = countryName
        ? name === countryName
        : name === firstDestination.name;

    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        if (!countryName) {
            params.set('country', firstDestination.name);
            history.replaceState(null, '', `${pathname}?${params.toString()}`);
        }
    }, [pathname, firstDestination, searchParams, countryName]);

    return (
        <button
            //   href={"/?country=" + destination.name}
            key={_id}
            onClick={() => {
                const params = new URLSearchParams(searchParams);
                params.set('country', name);
                history.replaceState(
                    null,
                    '',
                    `${pathname}?${params.toString()}`,
                );
            }}
            className={`min-w-fit w-fit px-[6px] py-[4px] text-[12px] md:text-sm text-black rounded-full cursor-pointer transition-all border flex items-center gap-x-[5px] bg-white ${
                isActive && '!bg-[#ff612c]'
            }`}
        >
            <img
                src={image}
                alt={'img_' + name}
                className="w-[24px] h-[24px] shadow-md rounded-full object-cover"
            />
            <span
                className={`GTE_light text-xs sm:text-sm text-black rounded-full ${
                    isActive && 'text-white '
                }`}
            >
                {name}
            </span>
        </button>
    );
};

export default Tab;
