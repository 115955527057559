export const commonSettings = {
    slidesPerRow: 1,
    slidesToShow: 5,
    touchThreshold: 300,
    infinite: false
};
export const tourCardSetting = {
    ...commonSettings,
    responsive:
        [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4.8,
                    slidesToScroll: 3,
                    initialSlide: 4.8
                }
            },
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 4.7,
                    slidesToScroll: 3,
                    initialSlide: 4.7
                }
            },
            {
                breakpoint: 1070,
                settings: {
                    slidesToShow: 4.4,
                    slidesToScroll: 3,
                    initialSlide: 4.4
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4.3,
                    slidesToScroll: 3,
                    initialSlide: 4.3
                }
            },
            {
                breakpoint: 975,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 911,
                settings: {
                    slidesToShow: 3.7,
                    slidesToScroll: 1,
                    initialSlide: 3.7
                }
            },
            {
                breakpoint: 865,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    initialSlide: 3.5
                }
            },
            {
                breakpoint: 790,
                settings: {
                    slidesToShow: 3.3,
                    slidesToScroll: 1,
                    initialSlide: 3.3
                }
            },
            {
                breakpoint: 765,
                settings: {
                    slidesToShow: 3.6,
                    slidesToScroll: 1,
                    initialSlide: 3.6
                }
            },
            {
                breakpoint: 714,
                settings: {
                    slidesToShow: 3.2,
                    slidesToScroll: 1,
                    initialSlide: 3.2
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 2.9,
                    slidesToScroll: 1,
                    initialSlide: 2.9
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.6,
                    slidesToScroll: 1,
                    initialSlide: 2.6
                }
            },
            {
                breakpoint: 442,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                    initialSlide: 2.2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2.1,
                    slidesToScroll: 1,
                    initialSlide: 2.1
                }
            }
        ]
}
export const hotelCardSettings = {
    ...commonSettings,
    responsive:
        [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4.8,
                    slidesToScroll: 3,
                    initialSlide: 4.8
                }
            },
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 4.7,
                    slidesToScroll: 3,
                    initialSlide: 4.7
                }
            },
            {
                breakpoint: 1070,
                settings: {
                    slidesToShow: 4.4,
                    slidesToScroll: 3,
                    initialSlide: 4.4
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4.3,
                    slidesToScroll: 3,
                    initialSlide: 4.3
                }
            },
            {
                breakpoint: 975,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 911,
                settings: {
                    slidesToShow: 3.7,
                    slidesToScroll: 1,
                    initialSlide: 3.7
                }
            },
            {
                breakpoint: 865,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    initialSlide: 3.5
                }
            },
            {
                breakpoint: 790,
                settings: {
                    slidesToShow: 3.3,
                    slidesToScroll: 1,
                    initialSlide: 3.3
                }
            },
            {
                breakpoint: 765,
                settings: {
                    slidesToShow: 3.6,
                    slidesToScroll: 1,
                    initialSlide: 3.6
                }
            },
            {
                breakpoint: 714,
                settings: {
                    slidesToShow: 3.2,
                    slidesToScroll: 1,
                    initialSlide: 3.2
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    initialSlide: 2.5
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 1,
                    initialSlide: 2.4
                }
            },
            {
                breakpoint: 442,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                    initialSlide: 2.2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2.1,
                    slidesToScroll: 1,
                    initialSlide: 2.1
                }
            }
        ]
}
export const destinationCardSetting = {
    ...commonSettings,
    responsive:
        [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 5.5,
                    slidesToScroll: 3,
                    initialSlide: 5.5
                }
            },
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 5.2,
                    slidesToScroll: 3,
                    initialSlide: 5.2
                }
            },
            {
                breakpoint: 1070,
                settings: {
                    slidesToShow: 4.6,
                    slidesToScroll: 3,
                    initialSlide: 4.6
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4.3,
                    slidesToScroll: 3,
                    initialSlide: 4.3
                }
            },
            {
                breakpoint: 975,
                settings: {
                    slidesToShow: 4.3,
                    slidesToScroll: 1,
                    initialSlide: 4.3
                }
            },
            {
                breakpoint: 911,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 865,
                settings: {
                    slidesToShow: 3.8,
                    slidesToScroll: 1,
                    initialSlide: 3.8
                }
            },
            {
                breakpoint: 790,
                settings: {
                    slidesToShow: 3.9,
                    slidesToScroll: 1,
                    initialSlide: 3.9
                }
            },
            {
                breakpoint: 765,
                settings: {
                    slidesToShow: 3.9,
                    slidesToScroll: 1,
                    initialSlide: 3.9
                }
            },
            {
                breakpoint: 714,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 2.9,
                    slidesToScroll: 1,
                    initialSlide: 2.9
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    initialSlide: 2.5
                }
            },
            {
                breakpoint: 442,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                    initialSlide: 2.2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2.1,
                    slidesToScroll: 1,
                    initialSlide: 2.1
                }
            }
        ]
}
export const packagesCardSetting = {
    ...commonSettings,
    responsive:
        [
            {
                breakpoint: 3500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 2.6,
                    slidesToScroll: 3,
                    initialSlide: 2.6
                }
            },
            {
                breakpoint: 1070,
                settings: {
                    slidesToShow: 3.2,
                    slidesToScroll: 3,
                    initialSlide: 3.2
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.8,
                    slidesToScroll: 3,
                    initialSlide: 2.8
                }
            },
            {
                breakpoint: 975,
                settings: {
                    slidesToShow: 2.7,
                    slidesToScroll: 1,
                    initialSlide: 2.7
                }
            },
            {
                breakpoint: 911,
                settings: {
                    slidesToShow: 2.7,
                    slidesToScroll: 1,
                    initialSlide: 2.7
                }
            },
            {
                breakpoint: 865,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    initialSlide: 2.5
                }
            },
            {
                breakpoint: 790,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    initialSlide: 2.5
                }
            },
            {
                breakpoint: 765,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    initialSlide: 2.5
                }
            },
            {
                breakpoint: 714,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                    initialSlide: 2.2
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1,
                    initialSlide: 1.3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 442,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
}
